#customProjImage .deleteIconWrapper {
  position: relative;
  top: 10px;
  right: 30px;
  z-index: 100;
}

#customProjImage .editIconWrapper {
  position: relative;
  top: -20px;
  right: 30px;
  z-index: 100;
}

#customProjImage .editIcon {
  border: 1px solid #029629;
  border-radius: 20px;
  color: #029629;
  padding: 2px;
}

#customProjImage .closeIcon {
  border: 1px solid #f00;
  border-radius: 20px;
  color: #f00;
  padding: 2px;
}
