.drawerItemSelected {
  background-color: #525080;
}

.drawerItem {
  background-color: #434166;
}
.drawerItemText {
  color: #7e7d91;
}

.drawerItemTextSelected {
  color: #fff;
}

#common {
  padding-inline: 15px;
  padding-block: 20px;
}

#common .wrapper {
  margin-bottom: 30px;
}

#common .title {
  font-size: x-large;
  margin-bottom: 10px;
}

#common .subTitle {
  font-size: large;
  margin-bottom: 10px;
}

#common .text {
  font-size: medium;
  margin-bottom: 10px;
}
