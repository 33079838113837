.section5 {
  width: 100%;
  background-color: #f0f3f1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 70px 0px 70px 0px;
}

.section5_left {
  max-width: 47.583%;
}

.section5_right {
  max-width: 52.417%;
}

.section5_subDiv5 {
  width: 1200px;
}

.section5_subDiv5_sub {
  display: flex;
}

.section5_left_heading {
  font-family: "Fredoka", Sans-serif;
  margin: 1rem 0;
}
.section5_left_heading h2 {
  font-size: 41px;
  font-weight: 700;
  line-height: 1;
}

.section5-heading-title {
  font-family: "Fredoka", Sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
}

.elementor-clearfix {
  color: #65687a;
  line-height: 23px;
  font-family: "Fredoka", Sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.section5_right_heading {
  text-align: center;
  color: #003d13;
  font-family: "Fredoka", Sans-serif;
}

.section5_right_form {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 40px;
}

.section5_right_form input,
.option {
  color: #98b0a0;
  font-size: 15px;
  border-color: #98b0a0;
  border-width: 1px 1px 1px 1px;
  padding: 8px 10px;
  width: 100%;

  background-color: transparent;
  margin: 0.5rem 0;
}
.input_top_heading {
  margin-bottom: 1.1rem;
  font-size: 22px;
  font-weight: 500;
}
.btn_submit {
  color: #fff;
  background-color: #003d13;
  font-size: 15px;
  border-color: #98b0a0;
  border-width: 1px 1px 1px 1px;
  padding: 8px 10px;
  margin-top: 1rem;
  width: 100%;
  font-family: "Fredoka", Sans-serif;
}

.select_service_div {
  font-size: 1rem;
  font-weight: 500;
}

.select_service_span {
  margin: 0.8rem 0;
  display: block;
  color: #54595f;
}

@media screen and (max-width: 1300px) {
  .section5 {
    width: 100%;
  }

  .section5_subDiv5 {
    width: 1000px;
  }
}

@media screen and (max-width: 1200px) {
  .section5_right {
    max-width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .section5_subDiv5_sub {
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;

    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .section5_right {
    max-width: 90%;
    margin-top: 40px;
  }

  .section5_left {
    max-width: 90%;
    text-align: center;
  }
  .section5_left_heading h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
  }
  .section5_left_heading {
    text-align: center;
    margin-top: 100px;
  }
  .section5-heading-title {
    font-size: 17.5px;
  }
}

@media screen and (max-width: 767px) {
  .section5_right {
    max-width: 100%;
  }
  .section5_right_form {
    margin-right: 0;
    width: 80%;
  }

  .section5_left {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }
  .section5_left_heading h2 {
    font-size: 23px;
    font-weight: 700;
    line-height: 1;
  }
}
@media screen and (max-width: 480px) {
  .section5_left_heading h2 {
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
  }
  .elementor-clearfix {
    font-size: 17px;
    line-height: 19px;
  }
  .section5_left_para {
    padding-left: 3px;
    padding-right: 3px;
  }
}
