#qna .title {
  font-size: x-large;
  margin-bottom: 10px;
}

#qna .subTitle {
  font-size: large;
  margin-bottom: 10px;
}

#qna .text {
  font-size: medium;
  margin-bottom: 10px;
}

#qna .textInput {
  width: 90%;
  border-radius: 5px;
  padding: 5px;
  border-width: 1px;
  border-color: #1e1e1e30;
}

#qna .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
