@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", Sans-serif;
}

.section6 {
  padding: 70px 0px 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section6_subDiv6 {
  width: 1200px;
  /* margin-bottom: 50px; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  text-align: center;
  padding: 0px 10px;
  align-items: self-start;
  margin-bottom: 10px;
}

.heading h3 {
  font-size: 41px;
  vertical-align: baseline;
  color: #010523;
  font-family: "Fredoka", Sans-serif;
  font-weight: 500;
  line-height: 1;
}

.google_box {
  padding-top: 40px;
  display: flex;
  justify-content: space-around;
}

.google_box_A_p3Div {
  color: #000422;
  line-height: 25px;
}

.google_box_comon {
  margin-bottom: 20px;
}

.google_box_A_imgDiv {
  height: 40px;
}

.google_box_A_starlogoDiv img {
  height: 17px;
}

.google_box_A_starlogoDiv {
}

.google_box_p_comon {
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  color: #1f5530;
}

.google_box_comon_home {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #658a71;
}

.google_box_A {
  width: 33.333%;
  text-align: center;
  padding: 25px;
  margin: 20px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #98b0a0;
  transition: 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.google_box_A p {
  font-size: 17px;
}

.google_box_A_imgDiv img {
  width: 42%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  height: 3rem;
}

.top_padding {
  margin-top: 70px;
}

@media screen and (max-width: 1200px) {
}

@media (max-width: 1024px) {
}

@media screen and (max-width: 960px) {
  .section6_subDiv6 {
    width: 768px;
  }
}

@media (min-width: 767px) {
}

@media (max-width: 767px) {
  .section6_subDiv6 {
    width: 100%;
  }
  .google_box {
    flex-wrap: wrap;
  }

  .google_box_A {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .heading h3 {
    font-size: 30px;
  }

  .google_box_A_imgDiv img {
    width: 30%;
    height: auto;
  }

  .google_box_A_starlogoDiv img {
    margin-top: 20px;
    width: 34%;
    height: auto;
  }
}

@media (max-width: 600px) {
}

@media (max-width: 400px) {
}
