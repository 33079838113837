.botom_section_footer {
  width: 100%;
  background-color: #000422;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.botom_section_footer_subDiv {
  width: 1200px;
  display: flex;
  padding: 70px 0;
  display: flex;
  justify-content: center;
}

.first_footer_div {
  width: 36.166%;
}

.second_footer_div {
  width: 30.14%;
}

.third_footer_div {
  width: 33.33%;
}

.first_footer_div {
  padding-right: 1.875em;
  /* padding-right: 90px; */
  /* border-right: 1px solid red; */
}

/* .first_second_third_hei_wid_defalt{
    width: 25.25em;
    height: 39.375em;
    border-left: 2px solid white;
} */

.Roof_logoImg {
  width: 12.2rem;
  /* height: 11rem; */
  /* width: 100%; */
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

.Img_footer_logo_center {
  display: flex;
  justify-content: center;
}

.Roof_logoImg img {
  /* width: 84%; */
  /* max-width: 100%; */
  width: 100%;
  height: 100%;
}

.Expertise_award_img {
  display: flex;
}

.first_footerDiv_ul_policy {
  display: flex;
  
}
.first_footerDiv_ul_policy li{
  cursor: pointer;
}

.second_div_socialmedia_icon {
  display: flex;
}

li {
  list-style: none;
}

.first_div_para {
  line-height: 23px;
  margin-bottom: 1em;
}

.award_img_box_defalt img {
  width: 100%;
  height: 154px;
}

.award_img_box_first,
.award_img_box_second {
  width: 50%;
}

.award_img_box_first {
  padding-right: 0.625rem;
}

.award_img_box_second {
  padding-left: 0.625em;
}

.first_footerDiv_ul_policy {
  display: flex;
  justify-content: center;
  line-height: 19px;
  padding: 0 0.46875em 0 0;
}

/* second div style */

.second_footer_div {
  padding: 0 1.875rem;

  border-left: 1px solid rgb(84, 88, 94, 0.4);

  /* border-left: 0.1px solid #54595F; */
}

/* .heading_p_all_width_default{
        width: 18.8046875rem;
        height: 1.1875rem;
} */

.second_box_icon {
  padding-right: 0.625rem;
  color: #98b0a0;
}

.contect_info_box {
  padding-bottom: 80px;

}

.second_div_socialmedia_icon {
  padding-bottom: 20px;
}

.social_media_icon {
  
  margin: 0 0.2rem;
  
}

.logo_featured_houzz {
  padding: 8px;
  border-width: 0 1px 1px 0;
  border: 1px solid #dddddd;
  width: 10rem;
  height: 2.5rem;
  margin-bottom: 3rem;
}

.logo_featured_houzz img {
  width: 100%;
  height: 100%;
}

/* .div{
    padding-bottom: 20px;
} */

/* .footer-heading-title{
    background-color: red;
} */

.footer-heading-style-defalt {
  /* background-color: blue; */
  color: #ffffff;
  font-family: "Fredoka", Sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
}

.third_footer_div {
  border-left: 1px solid rgb(84, 88, 94, 0.4);
  padding-left: 1.8rem;
}

.contect_info_common_box {
  padding-bottom: 1rem;
  /* width: 19rem; */
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.contect_info_icon_text_common {
  /* background-color: slateblue; */
  width: 270px;
  display: block;
  padding-top: 0.5rem;
}

.CONTACT_us_text_box {
  margin-bottom: 1.25rem;
}

.p-m-b8 {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.our_company_ul li,
.Services_ul li,
.blog_up_footer_ul li {
  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
}

.our_company_ul,
.Services_ul,
.blog_up_footer_ul {
  padding-bottom: 1rem;
}

.Read_more p {
  font-size: 12px;
  color: #54595f;
  font-weight: 700;
  padding: 0.8rem 0;
  /* margin: .5rem 0; */
}

.blog_heading h3 {
  font-size: 1.06rem;
}

@media screen and (max-width: 1025px) {
  .botom_section_footer {
    padding-left: 30px;
    padding-right: 30px;
  }

  .botom_section_footer_subDiv {
    flex-wrap: wrap;
  }

  .first_footer_div {
    width: 100%;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  .second_footer_div,
  .third_footer_div {
    width: 50%;
    font-size: 16px;
  }
  .second_footer_div {
    padding-left: 60px;
    border-left: 0;
  }
  .third_footer_div {
    padding-left: 10px;
  }
  .contect_info_common_box {
    justify-content: flex-start;
  }
  .contect_info_icon_text_common {
    /* width: 100%; */
  }
  .first_footerDiv_ul_policy {
    margin: 10px 0px;
    font-size: 14px;
  }
  .first_footerDiv_ul_policy li {
    padding: 0 10px;
    align-items: center;
    
  }
  

  .award_img_box_defalt img {
    max-width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .second_footer_div,
  .third_footer_div {
    width: 100%;
  }
  .last_copyright_box {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .third_footer_div {
    padding-left: 60px;
  }
}
@media screen and (max-width: 767px) {
  
.first_footerDiv_ul_policy {
  display: flex;
  justify-content: center;
  font-size: 22px;
  line-height: 19px;
  padding: 0 0.46875em 0 0;
  
}
.first_footerDiv_ul_policy:hover{
  color: chartreuse;
}


}


