#customText .title {
  font-size: x-large;
  margin-bottom: 10px;
}

#customText .subTitle {
  font-size: large;
  margin-bottom: 10px;
}

#customText .text {
  font-size: medium;
  margin-bottom: 10px;
}

#customText .newsInput {
  width: 90%;
  border-radius: 5px;
  padding: 5px;
  border-width: 1px;
  border-color: #1e1e1e30;
}
