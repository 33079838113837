.privacy-Main {
  width: 100%;
  background-color: hsla(240, 17%, 77%, 0.129);
  display: flex;
  justify-content: center;
}
.privacy-sub {
  width: 80%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  padding: 100px;
  line-height: 30px;
  font-size: 18px;
  margin-top: 100px;
  margin-bottom: 150px;
}
.privacyh {
  margin-bottom: 30px;
  font-size: 42px;
}
.privacy_heading {
  margin-top: 40px;
  margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
  .privacy-sub {
    width: 96%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    padding: 20px;
    line-height: 20px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .privacyh {
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 42px;
  }
}
