@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", Sans-serif;
}

.section3 {
  /* background-image: url("./images/HomesPhoto.jpg"); */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 77777;
  padding: 80px 20px;
}

.section3_subDiv3::before {
  content: "";
  right: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.section3_subDiv3 div {
  position: relative;
}

.section3_subDiv3 {
  width: 1200px;
  height: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 99999;
}

.section3_subDiv3_h1 {
  font-size: 52px;
  font-family: "Fredoka", sans-serif;
  font-weight: 500;
}

.subDiv3_para {
  padding-top: 12px;
  padding-bottom: 10px;
  font-family: "Fredoka", sans-serif;
  font-size: 16px;
}

.btn_A {
  color: white;
  margin-left: 0.9rem;
  padding: 14px 35px;
  text-align: center;
  text-transform: uppercase;
  width: 16rem;
  font-size: 1rem;
  background-color: black;
  transition: all 0.3s;
  border: none;
  margin-top: 30px;
}

.btn_A:hover {
  background-color: #fff;
  border-color: #000422;
  color: #000;
  font-weight: 600;
  border-radius: 10px;
}

.btn_B {
  margin-top: 30px;
  color: white;
  margin-left: 1.2rem;
  padding: 14px 35px;
  text-align: center;
  text-transform: uppercase;
  width: 16rem;
  border: 1px solid #ffffff;
  /* border: 2px solid black; */
  background-color: transparent;
  transition: all 0.3s;
}

.btn_B:hover {
  background-color: #000422;
  border-color: #000422;
  border-radius: 10px;
}

.btnB_num {
  padding-left: 10px;
  transition: all 0.3s;
}

.btnB_num:hover {
  display: inline-block;
  scale: 1.4;
  padding-left: 20px;
}

@media screen and (max-width: 1200px) {
  .section3_subDiv3 {
    width: 1000px;
  }
}

@media (max-width: 1024px) {
}

@media screen and (max-width: 960px) {
  .section3_subDiv3 {
    width: 768px;
  }
}

@media (min-width: 767px) {
  .section3_hero {
    width: 60%;
    margin-right: 2rem;
  }

  .section3_subDiv3_h1 {
    line-height: 52px;
  }
}

@media (max-width: 767px) {
  .section3_subDiv3 {
    width: 100%;
  }

  .section3_subDiv3 {
    flex-direction: column-reverse;
    align-items: stretch;
  }

  .section3_hero {
    text-align: center;
  }

  /* .video-container {
    width: 100%;
    margin-bottom: 2rem;
  } */

  .btn_A,
  .btn_B {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .section3_subDiv3_h1 {
    font-size: 32px;
  }

  /* section.section2 {
    padding: 20px;
    height: auto;
  } */
}

@media (max-width: 600px) {
  .section3_subDiv3 {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .section3_subDiv3_h1 {
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .btn_B {
    margin-left: 0;
  }
}
