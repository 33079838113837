/* html {
    height: 100%;
    background: #141e30;
  } */
/* body {
    font-family: sans-serif;
  } */

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", sans-serif;
  /* overflow-x: hidden; */
}
.Finance_mainBox {
  width: 100vw;
}


.Finance_Img_Form {
  /* background-image: url("./image/man-with_roofing.jpg"); */
  /* height: 80vh; */
  width: 100%;
  object-fit: cover;

  background-size: cover;
  background-position: center;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  padding-top: 50px;
  padding-bottom: 50px;
}

.Finance_up_img_left_right_box {
  /* width: 100%; */
  /* height: 80vh; */
  display: flex;
  justify-content: space-evenly;
  /* background-color: violet; */
}

.left_posterUp_heading_box {
  margin: 1rem 0;
}
.left_posterUp_heading_box h1 {
  font-size: 3rem;
  color: #fff;
  font-family: "Fredoka", sans-serif;
}

.left_posterUp_para_box {
  margin: 1rem 0;
}
.left_posterUp_para_box p {
  font-size: 1rem;
  color: #fff;
  line-height: 1.9rem;
  font-family: "Fredoka", sans-serif;
}
.left_posterUp {
  width: 50%;

  margin: auto 0;
}
.right_posterUp {
  width: 30%;
}

.login-box {
  width: 400px;
  padding: 10px 30px;
  /* margin: 6rem 0;  */
  background-color: rgba(0, 0, 0, 0.8);
  /* background: #141e30; */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.login-box form .loginButton {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.login-box a:hover {
  background: #131414;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
}

/* KEYFRAMES */

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

/* USING KEYFRAMES */

.login-box a span:nth-child(1) {
  animation: btn-anim1 1s linear infinite;
}

.login-box a span:nth-child(2) {
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

.login-box a span:nth-child(3) {
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

.login-box a span:nth-child(4) {
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

/* okra  */

@keyframes image1 {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.3);
  }
  30% {
    transform: scale(0.3) translate(-50%, -25%);
  }
  35% {
    opacity: 1;
    transform: scale(0.5) translate(-220%, 0%);
  }
  36% {
    opacity: 0;
  }
  90% {
    opacity: 0;
    transform: scale(1);
  }
  95% {
    opacity: 0.5;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0%, 0%);
  }
}
@keyframes image2 {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.3);
  }
  30% {
    transform: scale(0.3) translate(0%, 0%);
  }
  35% {
    transform: scale(1) translate(0%, 0%);
    transform-origin: center center;
  }
  55% {
    transform: scale(1) translate(0%, 0%);
    transform-origin: 0% 80%;
  }
  60% {
    animation-timing-function: ease-out;
    transform: scale(0.3);
  }
  65% {
    transform: scale(0.3);
  }
  70% {
    transform: scale(0.3) translate(-250%, 0%);
    transform-origin: 0% 80%;
  }
  100% {
    transform: scale(0.3) translate(-250%, 0%);
  }
}
@keyframes image3 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  10% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.3);
  }
  30% {
    transform: scale(0.3) translate(50%, 25%);
  }
  35% {
    height: 600px;
    transform: scale(0.5) translate(200%, 0%);
  }
  55% {
    height: 300px;
    transform: scale(0.5) translate(200%, 0%);
  }
  60% {
    transform: scale(0.6) translate(100px, 0%);
  }
  65% {
    height: 300px;
    transform: scale(0.6) translate(100px, 0%);
  }
  70% {
    height: 600px;
    transform: scale(1) translate(0%, 0%);
  }
  90% {
    opacity: 1;
    transform: scale(1) translate(0%, 0%);
  }
  95% {
    opacity: 0.5;
    transform: scale(0.3);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(0%, 0%);
  }
}

@media screen and (min-width: 1024px) {
  .login-box {
    width: 300px;
    margin-right: 100px;
  }
  .left_posterUp_heading_box h1 {
    font-size: 42px;
  }
  .left_posterUp {
    margin-left: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .login-box {
    width: 300px;
    margin-right: 70px;
  }
  .left_posterUp_heading_box h1 {
    font-size: 42px;
  }
  .left_posterUp {
    margin-left: 50px;
  }
}

@media screen and (min-width: 768px) {
  .login-box {
    margin-left: 50px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .login-box h2 {
    font-size: 18px;
  }
  .left_posterUp_heading_box h1 {
    font-size: 32px;
  }
  .login-box {
    width: 280px;
    margin-right: 50px;
    margin-left: 20px;
  }
  .left_posterUp {
    margin-left: 40px;
  }
}

@media screen and (max-width: 530px) {
  .Finance_up_img_left_right_box {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    justify-items: center;
    align-items: center;
  }
  .login-box {
    margin-right: 0;
    margin-top: 10px;
  }
  .left_posterUp_heading_box {
    width: 100%;
  }
  .left_posterUp_para_box p {
    font-size: 19px;
  }
  .left_posterUp {
    width: 90%;
    /* margin: 0 80px; */
    padding-right: 5px;
    align-items: center;
  }
  .left_posterUp_heading_box h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    /* width: 100%; */
    font-size: 22px;
    color: #000;
  }
}

/* @media screen and (max-width:700px){
  .Finance_up_img_left_right_box{
    display: flex;
    flex-wrap: wrap;
  }
} */
