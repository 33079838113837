* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.projectSection {
  width: 100%;
  display: flex;
  justify-content: center;
}

.projectSection-Sub {
  width: 80%;
  display: flex;
  justify-content: center;

  background-color: white;
  width: 100%;
  /* height: auto; */
  gap: 20px;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: 70px;
}
.smallDiv {
  width: 300px;
  height: 200px;
  overflow: hidden;
}
.smallDiv img {
  width: 100%;
  transition: all 1s;
}
.smallDiv img:hover {
  scale: 1.1;
}

@media screen and (max-width: 575px) {
  .projectSection-Sub {
    width: 100%;
    display: flex;
    justify-content: center;

    background-color: white;
    width: 100%;
    /* height: auto; */
    gap: 20px;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .smallDiv {
    width: 35%;
  }
}
