

.section9 {
  width: 100%;
  /* background-image: url("./images/about_poster2.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 130px 0px 130px 0px;
  line-height: 1.85;
  /* object-fit: cover; */
}

.section9_subDiv9 {
  width: 100%;
  background-color: white;
  text-align: center;
  padding-top: 20px;
  background-color: transparent;
  z-index: 33;
  
}
.section9::before {
  content: "";
  right: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 40, 0.4);
  z-index: 01;
}
.section9_subDiv9 h1 {
  font-family: "Fredoka", sans-serif;
  font-size: 55px;
  font-weight: 500;
  color: white;
}
.section9_subDiv9 p {
  font-family: "Fredoka", sans-serif;
  font-size: 19px;
  line-height: 24px;
  color: white;
  font-weight: 400;
  margin-bottom: 1em;
  border-image-repeat: stretch;
}
.subtitle{
  padding-left: 50px;
  padding-right: 50px;
  line-clamp: 10;
}
