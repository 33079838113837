@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", Sans-serif;
}

.Dropdown_child {
  font-size: 13px !important;
}

.dropDown-Box {
  position: absolute;
  top: 60%;
  left: 39.5%;
  display: none;
  z-index: 999999999999;
}

.list:hover .dropDown-Box {
  display: block;
}

.dropDown-Box ul {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* border-radius: 20px; */
  /* background-color:green; */
  /* border: 1px solid; */
}

#service_child_link {
  box-shadow: none;
  background-color: #000;
  font-size: 9px;
  color: #fff;
  /* height: fit-content; */
  width: 140px;
}

#service_child_span {
  /* font-size: 100px; */
  color: white;
  /* text-align: center; */

  /* border-radius: 10px; */
}

.headerButton {
  position: relative;
  background: linear-gradient(
      277deg,
      rgba(213, 213, 213, 0.1) 0%,
      rgba(213, 213, 213, 0.02) 50%,
      rgba(42, 42, 42, 0.02) 50%,
      rgba(42, 42, 42, 0.2) 100%
    ),
    linear-gradient(
      295deg,
      rgba(73, 73, 73, 0.3) 0%,
      rgba(73, 73, 73, 0.1) 50%,
      rgba(229, 229, 229, 0.1) 50%,
      rgba(229, 229, 229, 0.03) 100%
    ),
    linear-gradient(
      145deg,
      rgba(77, 77, 77, 0.1) 0%,
      rgba(77, 77, 77, 0.1) 50%,
      rgba(123, 123, 123, 0.1) 50%,
      rgba(123, 123, 123, 0.1) 100%
    ),
    linear-gradient(
      54deg,
      rgba(51, 51, 51, 0.3) 0%,
      rgba(51, 51, 51, 0.1) 50%,
      rgba(29, 29, 29, 0.2) 50%,
      rgba(29, 29, 29, 0.03) 100%
    ),
    linear-gradient(
      170deg,
      rgba(9, 9, 9, 0.02) 0%,
      rgba(9, 9, 9, 0.02) 50%,
      rgba(243, 243, 243, 0.02) 50%,
      rgba(243, 243, 243, 0.02) 100%
    ),
    linear-gradient(
      264deg,
      rgba(214, 214, 214, 0.01) 0%,
      rgba(214, 214, 214, 0.01) 50%,
      rgba(153, 153, 153, 0.01) 50%,
      rgba(153, 153, 153, 0.01) 100%
    ),
    linear-gradient(90deg, #6330de, #4cd4e9);
  padding: 1em 1.5em;
  border: 1px solid black;
  border-radius: 25px 7px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.7s ease;
}

.headerButton:after {
  position: absolute;
  top: 20%;
  left: 50%;
  background: linear-gradient(45deg, #2e5e95, #3b83d1, #324e8d);
  width: 150px;
  height: 30px;
  content: "";
  transform: translate(-50%);
  border-radius: 25px 7px;
  filter: blur(20px);
  z-index: -1;
}

.headerButton:hover {
  border-radius: 7px 25px;
  background: linear-gradient(
    45deg,
    #ef0781 0%,
    #ef0781 6%,
    #d00a70 6%,
    #d00a70 25%,
    #b10d60 25%,
    #b10d60 40%,
    #93104f 40%,
    #93104f 45%,
    #74133e 45%,
    #74133e 53%,
    #55162e 53%,
    #55162e 66%,
    #36191d 66%,
    #36191d 100%
  );

  border: 1px solid white;
}

/* // menubar animation css */

.list {
}

.list a {
  position: relative;
  height: 30px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 30px 30px; */
  box-shadow: 0 0 10px 5px rgba(234, 234, 234, 0.4);
  text-decoration: none;
  overflow: hidden;
  color: black;
}

a.item span:nth-child(1) {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to left, #4a068d, #b43bff);
  animation: animate1 2s linear infinite;
}

@keyframes animate1 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.section2 {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;

  display: flex;
  justify-content: center;
  position: relative;
}

.section2_subDiv {
  width: 1200px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.ul-Div ul {
  /* height: 86vh; */
}

.openMenu {
  display: none;
  font-size: 25px;
  color: #000422;
}

.closemenu {
  display: none;
  margin-right: auto;
}

.closemenu {
  margin-left: 30px;
  margin-top: -10px;
  margin-right: 5px;
  font-size: 10px;
}

.imgDiv {
  width: 12.66%;
}

.imgDiv img {
  width: 100%;
  height: 100%;
  color: black;
  background-color: black;

  /* // extra me add ki h border */
}

.ul-Div {
  width: 87.33%;
  display: flex;
}

.ul-Div ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ul-Div ul li {
  list-style: none;
}

.ul-Div ul li > a {
  text-decoration: none;
  padding: 0 5px;
  margin: 0px 15px;
  /* color:rgba(245, 241, 241, 0.915); */
  /* color: white; */
  font-size: 13px;
  font-weight: 700;
}

.ul-Div ul button {
  padding: 10px 20px;
  background-color: white;
  color: #fff;
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .section2_subDiv {
    width: 1000px;
  }
  .dropDown-Box {
    /* position: absolute; */
    /* top: 60%; */
    left: 15.5rem;

    /* height:380px; */
    /* width: 130px; */
    /* background-color:black!important; */

    /* display: none; */
    /* z-index: 999999999999; */
  }
  .ul-Div ul li > a {
    color: #000;
    background-color: #fff;
  }
}

@media (min-width: 960px) {
  .dropDown-Box {
    /* position: absolute; */
    /* top: 60%; */
    /* left: 270px; */

    /* height:380px; */
    /* width: 130px; */
    /* background-color:black!important; */

    /* display: none; */
    /* z-index: 999999999999; */
  }
}

@media screen and (max-width: 960px) {
  .ul-Div ul {
    height: 86vh;
  }

  .dropDown-Box {
    /* position: sticky; */
    top: -12.5%;
    right: 45%;
    /* height:280px; */
    /* width: 0px; */
    background-color: #fff;
  }

  .block-menu {
    background-color: #fff;
  }

  /* .unorder_list_child li a{
    margin: 0;
    line-height: 0;
    padding: 0;
    gap: 0;


  } */
  .list:hover .dropDown-Box {
    /* width: 200px; */
    /* background-color: #2e5e95; */
  }

  .dropDown-Box ul {
    /* text-align: center; */
  }

  .section2_subDiv {
    width: 768px;
  }

  .openMenu {
    /* margin-left: 30px; */
    display: initial;
    margin-top: 3px;
  }

  .ul-Div {
    display: flex;
    column-gap: 20px;
    /* column-gap: 20px; */
  }

  .ul-Div ul li > a {
    margin-top: 4px;
    background-color: #000;
    width: 92vw;
    color: #fff;
    /* border-radius: 10px; */
    /* padding: 10px 10px; */
  }

  .ul-Div ul {
    /* height: 280px; */
    /* background-color: #6330de; */
    row-gap: 0px;
    flex-direction: column;
    justify-content: left !important;
    /* gap: 17px; */
    background-color: #fff;
    padding-top: 30px;
    z-index: 99999999;
    /* position: absolute; */
    top: 103px;
    width: 100vw;
    left: -100%;
    color: black;
    /* padding: 15px; */
  }

  .openMenu {
    background: #000422;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 40px;
  }

  .closemenu {
    display: block;
  }

  .ul-Div ul {
    row-gap: 0px;
    flex-direction: column;
    justify-content: left !important;
    /* gap: 7px; */

    z-index: 99999999;
    position: absolute;
    top: 103px;
    width: 100%;
    left: -100%;

    /* padding: 15px; */
  }

  .closemenu {
    font-size: 1.5rem;
    position: absolute;
    right: 3%;
    top: 3%;
    cursor: pointer;
  }

  .ul-Div ul li {
    width: 100%;
  }

  #dropDown_main {
    position: unset;
    /* background-color: blue; */
  }

  #service_child_main_box {
    position: unset;
    /* background-color: rgb(71, 255, 102); */
    margin-left: 14px;
    width: 92.2vw;
  }

  #service_child_span {
    position: unset;
    left: 0;
    padding: 0;
    margin: 0;
    background-color: brown;
  }

  #service_child_link {
    position: unset;
    padding: 0;
    margin: 0;
    width: 100%;
    font-weight: 600;
    background-color: #fff;
    color: #000;
    /* border-radius: 10px; */
  }
}

@media (min-width: 767px) {
  .ul-Div {
    justify-content: flex-end;
  }

  .btn_in_text {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section2_subDiv {
    width: 100%;
  }

  .ul-Div ul {
    top: 225px;
    /* background-color: red; */
  }

  button.header-button {
    width: 90%;
  }

  .ul-Div {
    display: flex;
    justify-content: center;
    column-gap: 20px;

    width: 100%;
    margin-top: 10px;
  }

  .dropDown-Box {
    /* position: sticky; */
    top: -31%;
    right: 41%;
    /* height:280px; */
    /* width: 5px; */
    background-color: #fff;
  }

  /* 
  section.section2 {
    padding: 20px;
    height: auto;
  } */

  .section2_subDiv {
    flex-wrap: wrap;
  }

  .imgDiv {
    width: 100%;
    max-width: 170px;
    height: auto;
  }

  .section2_subDiv {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .section2_subDiv {
    flex-wrap: wrap;
  }
}

@media (max-width: 400px) {
  .ul-Div ul {
    top: 227px;

    /* height: 260px; */
    /* background-color: #74133e; */
  }

  .ul-Div ul li > a {
    background-color: black;
    color: white;
    /* margin-top: 0.4px; */
    padding: 10px 10px;

    /* border-radius: 10px; */
  }
  #service_child_link {
    /* margin-top: 20px; */
  }
}
