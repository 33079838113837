#customImage .editIconWrapper {
  position: relative;
  top: -12px;
  right: 10px;
  z-index: 100;
}

#customImage .editIcon {
  border: 1px solid #029629;
  border-radius: 20px;
  color: #029629;
  padding: 2px;
}

#customImage .closeIcon {
  border: 1px solid #f00;
  border-radius: 20px;
  color: #f00;
  padding: 2px;
}
