* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", Sans-serif;
}
.section1 {
  width: 100%;
  background-color: hsla(237, 18%, 23%, 0.129);

  color: white;
  display: flex;
  justify-content: center;
  font-family: "Fredoka", sans-serif;

  /* border-style: double; */
  border-bottom: 0.1px solid #000422;
  padding-top: 5px;
  padding-bottom: 5px;
}

.subdiv {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: "Fredoka", sans-serif;
  font-size: 14.92px;
  font-style: normal;
  padding-left: 1rem;
  -webkit-font-smoothing: antialiased;
}
.subDiv_p p {
  font-size: 14px;
  font-family: "Fredoka", sans-serif;
  color: #000422;
}
.contactDiv {
  display: flex;
  justify-content: space-between;
  font-size: 14.92px;
  font-style: normal;
  line-height: 12px;
}
.contactDiv_ul {
  display: flex;
  justify-content: space-around;
  height: 3.5vh;
  align-items: center;
}
.contactDiv_ul li {
  list-style: none;
  margin-left: 20px;
  color: #000422;
}

@media (max-width: 600px) {
  .section1 {
    height: auto !important;
  }
  .subdiv {
    flex-wrap: wrap;
    justify-content: center;
    height: auto !important;
  }
  .contactDiv {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

@media (max-width: 400px) {
  .subdiv {
    width: 100%;
  }
}
