.section2_finance {
  align-items: center;
  background: #eee;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  /* min-height: 100vh; */
  justify-content: center;
  width: 100%;
  padding: 100px 0;
}

.hero {
  align-items: center;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  /* max-width: 80vw; */
  width: 80%;
}

.hero_para {
  width: 90%;
  /* background-color: violet; */
  padding-bottom: 3rem;
}

.hero_para p {
  line-height: 1.4;
  margin-bottom: 1em;
  /* max-width: 70%; */
  width: 100%;
  font-family: "Fredoka", sans-serif;
}

.hero-images {
  background: white;
  border-radius: 12px;
  flex-shrink: 0;
  height: 600px;
  overflow: hidden;
  position: relative;
  width: 400px;
}
.hero-images img {
  border-radius: 12px;
  height: 600px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 400px;
}
.hero-images img:nth-child(1) {
  animation: image1 13s infinite;
  z-index: 10;
}
.hero-images img:nth-child(2) {
  animation: image2 13s infinite;
  z-index: 9;
}
.hero-images img:nth-child(3) {
  animation: image3 13s infinite;
  z-index: 8;
}

.button {
  background-color: #333;
  border-radius: 8px;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  margin: 2rem 0;
  transition: all 0.4s;
}
.button:hover {
  background-color: #fff;
  color: #000;
  font-weight: bold;
}

.heading1 {
  font-size: 48px;
  margin-bottom: 1.5em;
  font-family: "Fredoka", sans-serif;
}

@media screen and (max-width: 1024px) {
  .hero-title {
    font-size: 36px;
  }
}

@media screen and (max-width: 960px) {
  .hero {
    display: flex;
    flex-direction: column;
  }
  .hero-images {
    background: white;
    border-radius: 12px;
    flex-shrink: 0;
    height: 600px;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-top: 40px;
  }
  .hero-images img {
    border-radius: 12px;
    height: 600px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .hero_para {
    width: 100%;
    /* background-color: #03e9f4; */
    margin: 0;
    padding: 0;
  }
  .button {
    display: inline-block;
    margin-top: 20px;
  }
}

@media screen and (max-width: 530px) {
  .button {
    font-size: 14px;
    font-weight: 600;
  }
}
