@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", Sans-serif;
}

.section7 {
  width: 100%;
  padding: 70px 0 70px 0;
  background-color: #f4f4f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section7_subDiv7 {
  width: 1200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.section7_subDiv7 .heading {
  margin-bottom: 20px;
}

.section7_subDiv7 .services {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.section7_subDiv7_small {
  width: 31%;
  /* height: auto; */
  gap: 20px;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.section7_subDiv7_small img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  padding: 10px;
}

.section7_subDiv7_small {
}

.para_heading_comon {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #003d13;
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  font-family: "Fredoka", Sans-serif;
}

.desc_para_div p {
  color: #000422;
  --swiper-navigation-size: 44px;
  font-size: 17px;
}

/* .desc_para_div{
  width: 100%; 	

} */

.section7_subDiv7_small button {
  padding: 12px 80px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #003d13;
  font-family: "Fredoka", Sans-serif;
  font-size: 16px;
  color: white;
  border: none;
  text-transform: uppercase;
}

.section7_subDiv7_small button:hover {
  background-color: #000422;
}

@media screen and (max-width: 1200px) {
  .desc_para_div p {
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .section7_subDiv7 {
    width: 100%;
  }
}

@media (max-width: 1024px) {
}

@media screen and (max-width: 960px) {
  .section7_subDiv7 {
    width: 768px;
  }
}
@media (max-width: 767px) {
  .section7_subDiv7 {
    width: 100%;
  }
  .section7_subDiv7_sub {
    width: 100%;
  }

  .section7_subDiv7_small {
    width: 100%;
    padding: 10px 0 20px 0;
  }

  .section7_subDiv7_small img {
    height: 150px;
    object-fit: cover;
    padding: 0px 8px;
  }

  .para_heading_comon {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .section7_subDiv7_small button {
    width: 96%;
    padding: 9px;
  }

  .desc_para_div p {
    height: auto;
  }

  .section7_subDiv7 {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .heading h3 {
    font-size: 30px;
  }

  .services {
    flex-direction: column;
  }
}
