.loginContainer {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: #e7e6e4;
}

#login .title {
  font-size: 25px;
  margin-bottom: 20px;
}

#login .form {
  min-width: 50vw;
  max-width: 90vw;
  background-color: #fff;
  padding: 3rem 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

#login .inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

#login .input {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: "#E7E6E4";
  border-radius: 4;
}

#login .buttonContainer {
  display: flex;
  justify-content: center;
  align-self: center;
  width: fit-content;
  margin-top: 2rem;
  background-color: #e8f2fc;
  border-radius: 5px;
  cursor: pointer;
}

#login .submitBtnText {
  color: #0c1522;
  margin: 5px 10px;
}

#login .whiteText {
  color: #434166;
}

#login .logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

#login .searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 10px;
}

#login .inputField {
  display: flex;
  align-self: center;
  width: 100%;
  padding: 10px;
  border: #0c1522 solid 1px;
  border-radius: 5px;
}

#login .searchBtn {
  border-radius: 5px;
  background-color: #0c1522;
  padding: 9px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

#login .searchBtnText {
  color: #fff;
  margin: 0;
  text-align: center;
}
