.second_section {
  background-color: #f0f3f1;
  padding: 80px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second_sub_section {
  width: 80%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.subDiv10_B {
  background-color: #ffffff;
  padding: 50px;
}

.subDiv10_B_sub {
  display: flex;
  width: 100%;
  align-content: space-evenly;
  flex-wrap: wrap;
}

.headline_top {
  padding: 10px 0;
  color: #003d13;
  font-family: "Fredoka", Sans-serif;
  letter-spacing: 1px;
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  /* text-align: center; */
  /* align-items: center; */
}

.line_span {
  width: 140px;
  /* height: 2 */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #003d13;
  margin: 0 0 0 10px;
}
.heading_second_box {
  padding-bottom: 10px;
  margin: 20px 0;
}

.heading_second_box h2 {
  /* width: 80%; */

  color: #000422;
  font-family: "Fredoka", Sans-serif;
  font-size: 41px;
  font-weight: 700;
  line-height: 1.3;
}

.sub10_second_para p {
  color: #65687a;
  font-family: "Fredoka", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  padding: 0 0 20px 0;
}

.bottom_img_box {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 0;
}
.bottom_img_subDiv {
  width: 80%;
  position: relative;
}

.img_bottom_box img {
  width: 100%;
  /* height: 100%; */
  border-image-repeat: round;
  border-radius: 50px;
}
/* .img_bottom_box .text_bottom_img_up:hover{
    scale: 3;
    overflow: hidden;
} */
.imgbox_bottom {
}
.img_cover_up {
  width: 100%;
  height: 99%;
  background-color: rgb(0, 0, 0, 0.4);

  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.img_up_up_div {
  width: 95%;

  position: absolute;
  top: 25%;
  left: 2.5%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_bottom_img_up {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.text_bottom_img_up h1,
.text_bottom_img_up span,
.text_bottom_img_up p {
  margin: 15px 0;
  display: block;
  width: 100%;
}
.text_bottom_img_up h1 {
  font-size: xx-large;
  color: #fff;
  transition: all 0.7s;
}
.text_bottom_img_up h1:hover {
  scale: 1.3;
}
.text_bottom_img_up span {
  background-color: #ffffff;
  color: #000422;
  /* font-size: large; */
  font-weight: 700;
  padding: 10px 0;
  width: 45%;
  transition: all 0.5s;
}
.text_bottom_img_up span:hover {
  background-color: #000422;
  color: #ffffff;
}

.text_bottom_img_up p {
  width: 70%;
  line-height: 30px;
  font-size: 18px;
  color: #fff;
}

.effect5 {
  position: relative;
}
.effect5:before,
.effect5:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 25px;
  left: 30px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 35px 20px #777;
  -moz-box-shadow: 0 35px 20px #777;
  box-shadow: 0 35px 20px #777;
  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  transform: rotate(-8deg);
}
.effect5:after {
  -webkit-transform: rotate(8deg);
  -moz-transform: rotate(8deg);
  -o-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
  right: 30px;
  left: auto;
}

@media screen and (max-width: 1200px) {
  .second_section {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .text_bottom_img_up p {
    font-size: 16px;
  }
  .text_bottom_img_up span {
    width: 55%;
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .text_bottom_img_up p {
    font-size: 16px;
    line-height: 25px;
  }
  .text_bottom_img_up span {
    width: 55%;
    font-size: 14px;
  }
}
@media screen and (max-width: 900px) {
  .text_bottom_img_up span {
    padding: 10px 30px;
    font-size: 16px;
  }
  .text_bottom_img_up span {
    width: 55%;
    font-size: 14px;
  }

  .bottom_img_box {
    padding: 70px 70px;
  }

  .bottom_img_box {
    padding: 20px 10px;
  }
  .bottom_img_subDiv {
    width: 100%;
    padding: 50px 0px;
  }
  /* .text_bottom_img_up{
        width: 100%;
    } */
}

@media screen and (max-width: 765px) {
  .text_bottom_img_up {
    width: 100%;
  }
  .text_bottom_img_up h1 {
    font-size: 20px;
  }
  .img_cover_up {
    width: 100%;
  }
  .img_bottom_box img {
    height: 60vh;
    width: 100%;
  }
  .text_bottom_img_up span {
    padding: 10px 10px;
    font-size: 10px;
  }
}

@media screen and (max-width: 700px) {
  .text_bottom_img_up {
    width: 100%;
  }

  .line_span {
    display: none;
  }
  .headline_top {
    font-size: 23px;
    margin-right: 30px;
    font-weight: 600;
  }
}
@media screen and (max-width: 575px) {
  .headline_top {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
  }
  .text_bottom_img_up h1 {
    font-size: 22px;
  }
  .text_bottom_img_up p {
    /* font-size: 22px; */
    line-height: 1.2;
  }
  .img_up_up_div {
    width: 100%;
  }
  .heading_second_box span {
    width: 100%;
  }
  .heading_second_box h2 {
    font-size: 23px;
    font-weight: 600;
  }
  .subDiv10_B {
    padding: 23px;
    width: 100%;
  }
  .second_sub_section {
    width: 94%;
  }

  .line_span {
    display: none;
  }
}
