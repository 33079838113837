@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@100;200;300;400;500;600;700;800;900&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Fredoka", Sans-serif;
  /* font-family: 'Chivo', sans-serif; */
}

.section_footer {
  /* background-image: url("./images/Homes_Photo_footer_up.jpg"); */
  background-position: center;
  background-size: cover;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}
.section_footer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0.8;
}
.section_footer_subDiv {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  display: flex;

  align-items: center;
  color: #fff;
}
.section_footer_left,
.section_footer_right {
  width: 50%;
  margin-left: 50px;
}
.section_footer_right a {
  text-decoration: none;
  display: inline-block;
  width: 48%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  border-width: 0;
  padding: 10px;
}
.section_footer_right a:first-of-type {
  color: #000;
  background-color: #fff;
  transition: all 0.3s ease-in;
  margin-right: 10px;
}
.section_footer_right a:first-of-type:hover {
  background-color: black;
  color: white;
}
.section_footer_right a:last-of-type {
  color: #fff;
  background-color: #02010100;
  border: 1px solid #fff;
  margin-left: 10px;
}
.section_footer_right a:last-of-type:hover {
  background-color: black;
  color: white;
}

.section_footer_left h3 {
  font-size: 41px;
  font-weight: 700;
  margin-bottom: 20px;
}
.section_footer_left p {
  font-size: 17px;
  font-weight: 400;
  line-height: 23px;
  padding-bottom: 10px;
}

.section_footer_subDiv_sub {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .section_footer_subDiv {
    width: 1000px;
  }
}

@media screen and (min-width: 1025px) {
  .section_footer_right a:first-of-type {
    margin-right: 10px;
  }
  .section_footer_right a:last-of-type {
    margin-left: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .section_footer_subDiv {
    flex-direction: column;
    width: 100%;
  }
  .section_footer_left {
    width: 100%;
    text-align: center;
  }
  .section_footer_left p {
    padding-left: 70px;
    padding-right: 70px;
  }
  .section_footer_right {
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }

  .section_footer_right a {
    width: 35%;
    font-weight: 600;
    padding: 13px 0px;
  }
}

@media screen and (max-width: 960px) {
  .section_footer_subDiv {
    width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .section_footer_subDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 80%;
    align-items: center;
    /* padding-left: 0; */
    margin-left: 25px;
  }

  .section_footer_right a {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    /* margin-left: 0; */
  }
  .section_footer_right a:first-of-type {
    margin-right: 0;
  }
  .section_footer_right a:last-of-type {
    margin-left: 0;
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .section_footer_left {
    width: 100%;

    text-align: center;
  }
  .section_footer_left h3 {
    font-size: 32px;
  }
  .section_footer_left p {
    padding-left: 40px;
    padding-right: 40px;
    /* font-size: 20px; */
  }
  .section_footer_subDiv {
    /* padding-left: 0; */
    margin-left: 8px;
  }
}
